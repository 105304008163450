<template>
  <el-container class="patroladd">
     
    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item>权限管理</el-breadcrumb-item>
       <el-breadcrumb-item >角色权限</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.role_id">编辑角色模块权限</el-breadcrumb-item>
    </el-breadcrumb>

        <div><el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="">
    <el-form  :inline="true"  status-icon ref="form"  :model="form"  class="demo-ruleForm">
      <el-row>
         <el-col :span="11" class="tl" >
           <el-form-item label="角色名称："  class="w" label-width="28%"  prop='role_name'  :rules="[{required: true,message: '请输入角色名称', trigger: 'blur' },{ pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/, message: '不允许输入空格等特殊符号' }]">
          <el-input v-model="form.role_name" size="medium"  clearable></el-input>
        </el-form-item>
        </el-col>
      </el-row>
        <el-row>
        <div  v-for="(item,index) in menu_list" :key="index">
            <div >
                <el-col :span="24" class="tl"  v-if="!['打卡','博试质控','基地管理','红包配置'].includes(item.name)">
                    <el-checkbox-group  v-model="checkboxGroup1"  >
                        <el-checkbox   class="ml10 mb20 disib"  :label="item.id" :value="item.id" :key="item.id" border size="medium">
                            {{item.name}}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-col>
            </div>
           <!-- <div v-else>
               <el-col :span="24" class="tl" >
                   <el-checkbox-group  v-model="checkboxGroup1"  >
                       <el-checkbox   class="ml10 mb20 disib"  :label="item.id" :value="item.id" :key="item.id" border size="medium">
                           {{item.name}}
                       </el-checkbox>
                   </el-checkbox-group>
               </el-col>
               <el-col :span="10" class="tl" >
                   请输入内容:
                   <el-input style="width: 450px" v-if="!item.show_home" v-model="content[item.id]" placeholder="请输入内容"></el-input>
               </el-col>
           </div> -->

         </div>
        </el-row>
      <div class="tl mt15" style="text-align: center">
             <el-button  class=""  size="medium" type="primary" @click="submitForm('form')">提交</el-button>
          </div>
     </el-form>
     </el-main>
  </el-container>
</template>
<script>
import axios from '../../../../util/api'
import { local,session } from '../../../../util/util'
export default {
  data () {
    return {
      menu_list:'',
      activeNames: ['1'],
      aa:[],
      bb:[],
      cc:[],
      form: {
            company_id:'5',
            role_id:'',
            role_name:'',
            app_menu_list:[]
      },
        content:[],
       checked1: true,
        checked2: false,
        checkboxGroup1: [],
        list:[],
        list1:[],
        user_id_add:'',
        user_name_add:'',
    }
  },
     created () {
      this.user = local.get('user');
       this.user_id_add=this.user.user_id;
        this.user_name_add=this.user.user_name;
       this.form.role_id=session.get('params').id?String(session.get('params').id):'';
     this.init()
  },
  methods: {
     init (params) {
          axios.get('/pc/menu/appMenuList').then((v) => {
           this.menu_list=v.data.menu_list;
      })
       if(this.form.role_id){
          axios.get('/pc/role/singleCompanyMenu',{role_id:this.form.role_id}).then((v) => {
           this.form.role_name=v.data.role.role_name
            v.data.role.list.forEach(item=>{
                this.checkboxGroup1.push(item.menu_id)
            })
      })
       } 
    },
    getArrDifference(arr1, arr2) {
        return arr1.concat(arr2).filter(function(v, i, arr) {
            return arr.indexOf(v) === arr.lastIndexOf(v);
        });
    },
     submitForm(form){
         this.form.app_menu_list = [],
         this.checkboxGroup1.forEach(s=>{
             let param = {id:s,content:this.content[s]}
             this.form.app_menu_list.push(param);
         })
          let paramas={
            role_name:this.form.role_name,
            role_id:this.form.role_id,
            company_id:this.form.company_id
          }
         this.$refs[form].validate((valid) => {
         if (valid) {
            axios.get("/pc/role/exist", paramas).then(v => {
            if(this.form.role_id){
             axios.put('/pc/role/updateCompanyMenu', this.form).then((v) => {
              this.$router.back()
             })
          }else{
             axios.post('/pc/role/saveCompanyMenu', this.form).then((v) => {
              this.$router.back()
              })
          }
          });
        }else{
           this.$message.error('请检查表单必填项');
            return false
        }
         })
      },
      handleChange(){}
  }
}
</script>
<style lang="scss" scoped>
.patroladd{
   overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroladd-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}
  .patroladd-title1{height: 34px;line-height: 34px;}
.el-checkbox{    display: flex;
    justify-content: left;
    align-items: center;}
/deep/ .el-collapse-item .el-collapse-item__content{padding-bottom: 0px;}
}

</style>
